import { en } from "./dictionaries/en";
import { cz } from "src/i18n/dictionaries/cz";
import { Locales, defaultLocale } from "src/i18n/types";

export type Dictionary = typeof en;
export type DictionaryKey = keyof Dictionary;

/**
 * As keys are used BCP 47 locale identifiers
 * (see ECMAScript Intl standard)
 */
export const locales: Record<
  Locales,
  { title: string; dictionary: Dictionary }
> = {
  en: {
    title: "english",
    dictionary: en,
  },
  cz: {
    title: "čeština",
    dictionary: cz,
  },
} as const;

export const defaultDictionary = locales[defaultLocale].dictionary;
