import { QueryClient } from "@tanstack/react-query";
import { Exception, MenuResponse, PickUpPoint, Polygon, Price } from "src/_generated";
import { Locales } from "src/i18n/types";

export enum MODELS {
  LOCALE = "locale",
  AUTH_TOKEN = "auth_token",
  MENU = "menu",
  CUSTOMER_EXPECTIONS = "customer_expections",
  EXPECTIONS = "expections",
  PIRCES = 'prices',
  PICK_UP_POINTS = 'pick_up_points',
  POLYGONS = 'polygons'
}

export const store = {
  get: <T>(client: QueryClient, model: MODELS) =>
    client.getQueryData<T>([model]),
  locale: (client: QueryClient, data: Locales) => {
    client.setQueryData([MODELS.LOCALE], () => data);
  },
  authToken: (client: QueryClient, data: string) => {
    client.setQueryData([MODELS.AUTH_TOKEN], () => data);
  },
  menu: (client: QueryClient, data: MenuResponse) => {
    client.setQueryData([MODELS.MENU], () => data);
  },
  customerExpections: (client: QueryClient, data: string[]) => {
    client.setQueryData([MODELS.CUSTOMER_EXPECTIONS], () => data);
  },
  expections: (client: QueryClient, data: Exception) => {
    client.setQueryData([MODELS.EXPECTIONS], () => data);
  },
  prices: (client: QueryClient, data: Price) => {
    client.setQueryData([MODELS.PIRCES], () => data);
  },
  clear: (client: QueryClient) => client.clear(),
  polygons: (client: QueryClient, data: Polygon[]) => {
    client.setQueryData([MODELS.POLYGONS], () => data);
  },
  pickupPoints: (client: QueryClient, data:{result: PickUpPoint[]}) => {
    client.setQueryData([MODELS.PICK_UP_POINTS], () => data);
  },
} as const;
