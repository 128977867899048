import React, { ReactNode } from "react";

import { QueryProvider } from "src/fetcher/QueryProvider";
import { IntlProvider } from "src/i18n/IntlProvider";
import { HelmetProvider } from "react-helmet-async";

import "src/styles/global.css";
import "src/styles/variables.css";

type Props = {
  children: ReactNode;
};

export const Provider: React.FC<Props> = ({ children }) => (
  <QueryProvider>
    <HelmetProvider>
      <IntlProvider>{children}</IntlProvider>
    </HelmetProvider>
  </QueryProvider>
);
