import React from "react";
import { Router as BrowserRouter } from "react-router-dom";

import { useHistory } from "src/navigation/useHistory";
import { PagesSwitch } from "src/navigation/Switch";
import { history } from "src/navigation/store";
import { useQueryClient } from "@tanstack/react-query";
import { MODELS, store } from "src/fetcher/store";
import { useAccessToken } from "src/hooks/useAccessToken";

export const Router: React.FC = () => {
  const { location, action } = useHistory();
  const queryClient = useQueryClient();
  const storedToken = store.get<string>(queryClient, MODELS.AUTH_TOKEN);
  const isLoggedIn = storedToken ? true : false;
  const token = useAccessToken();

  const mode = isLoggedIn || token.accessToken ? "user" : "guest";

  return (
    <BrowserRouter
      location={location}
      navigationType={action}
      navigator={history}
    >
      <PagesSwitch mode={mode} />
    </BrowserRouter>
  );
};
