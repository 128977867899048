import React from "react";

import { Router } from "src/navigation/Router";
import { Provider } from "src/components/providers/Provider";

export const App: React.FC = () => (
  <Provider>
    <div className="app" data-testid="page">
      <Router />
    </div>
  </Provider>
);
