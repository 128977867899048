import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { TokenPayload } from "src/_generated";

// todo - do something if token is invalid (e.g. expired)
const tryDecode = (token: string): TokenPayload | null => {
  if (!token) return null;

  try {
    return jwtDecode<TokenPayload>(token);
  } catch (e) {
    return null;
  }
};

const LOCAL_STORAGE_KEY = "accessToken";

export const useAccessToken = (...deps: any[]) => {
  //TODO remove this logic
  const initialAccessToken = localStorage.getItem(LOCAL_STORAGE_KEY) || "";
  const initialTokenPayload = tryDecode(initialAccessToken);

  const [accessToken, _setAccessToken] = useState(initialAccessToken);
  const [tokenPayload, setTokenPayload] = useState(initialTokenPayload);
  const [isAuthenticated, setIsAuthenticated] = useState(!!initialTokenPayload);

  const setAccessToken = (newAccessToken: string) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, newAccessToken);
    const newTokenPayload = tryDecode(newAccessToken);

    setTokenPayload(newTokenPayload);
    _setAccessToken(newAccessToken);
    setIsAuthenticated(!!newTokenPayload);
  };

  useEffect(() => {
    const newAccessToken = localStorage.getItem(LOCAL_STORAGE_KEY) || "";
    setAccessToken(newAccessToken);
  }, deps);

  return { accessToken, setAccessToken, tokenPayload, isAuthenticated };
};
